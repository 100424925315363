<template>
  <div class="start-picker-days">
    <table class="table-condensed mx-auto">
      <thead>
        <tr>
          <th class="prev" @click.stop="$emit('change-month', -1)"><i class="glyphicon icon-arrow-left"></i></th>
          <th colspan="5" class="switch" @click.stop="$emit('change-mode', 'months')">{{title}}</th>
          <th class="next" @click.stop="$emit('change-month', 1)"><i class="glyphicon icon-arrow-right"></i></th>
        </tr>
        <tr>
          <th class="dow">Mo</th>
          <th class="dow">Tu</th>
          <th class="dow">We</th>
          <th class="dow">Th</th>
          <th class="dow">Fr</th>
          <th class="dow">Sa</th>
          <th class="dow">Su</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="week in daysByWeek">
          <td v-for="day in week" @click.stop="$emit('day-picked', day.label)" :class="day.css">{{day.label}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="7" class="today" style="display: none;">Today</th>
        </tr>
        <tr>
          <th colspan="7" class="clear" style="display: none;">Clear</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import { cal } from '../utils'
export default {
  props: {
    datetime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      today: Date.today(),
      date: Date.today()
    }
  },
  computed: {
    dateValue() {
      return Date.parse(this.datetime)
    },
    daysByWeek() {
      if (this.datetime == null) {
        return
      }
      const year = this.dateValue.getFullYear()
      const month = this.dateValue.getMonth()
      var self = this
      var daysByWeek = cal.monthDates(year, month, (d) => {
        var css = 'day'
        if (d.getMonth() < month)
          css = css + ' old'
        if ((d.getDate() < self.today.getDate()) && (d.getMonth() == self.today.getMonth()))
          css = css + ' old'
        if (d.getMonth() > month)
          css = css + ' new'
        if ( (d.getDate() == self.today.getDate()) && (d.getMonth() == self.today.getMonth()) )
          css = css + ' active'

        var event = {
          label: d.getDate(),
          css: css,
          date: d
        };
        return event;
      })
      return daysByWeek
    },
    title() {
      return this.dateValue.toString('MMMM yyyy')
    }
  }
}
</script>
