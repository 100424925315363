<template>
  <div class="start-picker-hours">
    <div class="start-picker-header">
      <div class="d-flex flex-row justify-content-center">
        <div class="prev picker-controls" @click.stop="$emit('change-day', -1)"><i class="glyphicon icon-arrow-right"></i></div> <span class="switch mx-2" @click.stop="$emit('change-mode', 'days')">{{title}}</span>
        <div class="next picker-controls" @click.stop="$emit('change-day', 1)"><i class="glyphicon icon-arrow-left"></i></div>
      </div>
      <div class="d-flex flex-row justify-content-center" v-if="(availabilities.length > 0) && forClientBooking"><span class="time-head">Arrival Time</span></div>
    </div>
    <div class="start-picker-body">
      <div v-for="hoursRow in availabilities" class="d-flex flex-row justify-content-center align-content-center flex-wrap">
        <div v-for="hour in hoursRow" :class="hourClass(hour.mode)" @click.stop="selectHour(hour)">{{hour12(hour.hour)}}</div>
      </div>
      <div v-if="availabilities.length == 0 && !loading">
        <div>No available time</div>
      </div>
      <div v-if="loading" class="d-flex flex-row justify-content-center"><span class="time-head"><spinner message="Checking availability" :show="true" :use-modal="false"></spinner></span></div>
    </div>
    <div v-if="(availabilities.length > 0) && forClientBooking" class="start-picker-footer mx-auto">
      <div class="timeaction-colors row">
        <div><strong></strong>Instant Booking</div>
        <div><strong></strong>We will confirm availability shortly</div>
        <div><strong></strong>Not Available</div>
      </div>
    </div>
    <div v-if="showMessage" class="start-picker-footer mx-auto">
      <div class="alert alert-warning m-0" role="alert">
        You can pick any date and hour
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import Spinner from '@/shared/spinner.vue'
export default {
  components: { Spinner },
  props: {
    availabilities: {
      type: Array,
      required: true
    },
    forBooking: {
      type: Boolean,
      default: true
    },
    datetime: {
      type: String,
      required: true,
/*      validator: function (value) {
        var time = Date.parse(value)
        if (time.toString() == 'Invalid Date') {
          console.error('Invalid Date: [', value, ']')
          return false
        }
        return true
      }
*/
    },
    loading: {
      type: Boolean,
      default: true
    },
    showAlert: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    dateValue() {
      return Date.parse(this.datetime)
    },
    forClient() {
      if (this.isStaff) {
        return false
      }
      return true
    },
    forClientBooking() {
      return this.forBooking && this.forClient
    },
    showMessage() {
      if (this.showAlert) {
        return (this.availabilities.length > 0) && !this.forClientBooking
      }
      return false
    },
    title() {
      return this.dateValue.toString('MMMM dS, yyyy')
    }
  },
  methods: {
    asDuration(hour24) {
      const start = this.hour12(hour24, {
        addAmPm: false
      })
      const end = this.hour12(hour24 + 1)
      return start + '-' + end
    },
    hour12(hour24, opts) {
      var date = this.dateValue.at({hour: hour24 })
      var minute = date.getMinutes();
      var ampm = '';
      if (opts === undefined)
        opts = {}
      if (opts.noMinutes === undefined)
        opts.noMinutes = true
      if (opts.addAmPm === undefined)
        opts.addAmPm = true

      if (opts.addAmPm) {
        ampm += (hour24 < 12 ? 'am' : 'pm');
      }
      hour24 = hour24 === 0 ? 12 : hour24 > 12 ? hour24 - 12 : hour24;

      const minutePart = ':' + (minute < 10 ? '0' : '') + minute
      var result = hour24
      if (!opts.noMinutes)
        result += minutePart
      return result + ampm
    },
    hourClass(mode) {
      return 'btn mx-1 ' + (this.forClient ? mode : 'for-staff')
    },
    selectHour(hour) {
      if ((hour.mode != 'unavailable') || (!this.forClientBooking)) {
        this.$emit('hour-picked', hour)
        $(this.$el).click()
      }
    }
  }
}
</script>
