<template>
  <div>
    <div class="datetimepicker-years">
      <table class="table-condensed">
        <thead>
          <tr>
            <th class="prev" @click.stop="$emit('change-year', -10)">><i class="glyphicon icon-arrow-left"></i></th>
            <th colspan="5" class="switch">{{title}}</th>
            <th class="next" @click.stop="$emit('change-year', 10)"><i class="glyphicon icon-arrow-right"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7">
              <span class="year" v-for="year in years" :class="{active: isActive(year)}" @click.stop="$emit('year-picked', year)">{{year}}</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="7" class="today" style="display: none;">Today</th>
          </tr>
          <tr>
            <th colspan="7" class="clear" style="display: none;">Clear</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    datetime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    dateValue() {
      return Date.parse(this.datetime)
    },
    startRange() {
      return Math.floor(this.dateValue.getFullYear() / 10) * 10 - 1
    },
    endRange() {
      return this.startRange + 12
    },
    title() {
      return (this.startRange + 1) + '-' + (this.endRange - 1)
    },
    years() {
      var years = new Array(12)
      years.fill(this.startRange)
      years.forEach((year, idx, years) => {
        years[idx] += idx
      })
      return years
    }
  },
  methods: {
    isActive(year) {
      return this.dateValue.getFullYear() == year
    }
  },
}
</script>
