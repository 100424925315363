<template>
  <div>
    <component
      v-bind:is="mode"
      :availabilities="availabilities"
      :datetime="tmpDateValue.toString()"
      :for-booking="forBooking"
      :loading="loading"
      :show-alert="showAlert"
      @change-mode="onChangeMode"
      @change-year="onChangeYear"
      @change-month="onChangeMonth"
      @change-day="onChangeDay"
      @year-picked="onYearPicked"
      @month-picked="onMonthPicked"
      @day-picked="onDayPicked"
      @hour-picked="onHourPicked"
    ></component>
    <slot name="spinner"></slot>
  </div>
</template>
<script>
import Vue from 'vue'

import moment from 'moment'
import {
  dateToAppFormat
} from '@/utils'

import Years from './years.vue'
import Months from './months.vue'
import Days from './days.vue'
import Hours from './hours.vue'

export default {
  props: {
    availabilities: {
      type: Array,
      default () {
        return []
      }
    },
    datetime: {
      type: String
    },
    minDate: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    formatString: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    forBooking: {
      type: Boolean,
      default: true
    },
    showAlert: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Years,
    Months,
    Days,
    Hours
  },
  data() {
    return {
      tmpDate: null,
      pickedDate: null,
      activeProgress: null,
      mode: null,
      showPicker: false
    }
  },
  computed: {
    formattedDate() {
      if (this.pickedDate) {
        return Date.parse(this.pickedDate).toString(this.formatString)
      }
      return ''
    },
    defaultDate() {
      if (this.datetime) {
        return Date.parse(this.datetime)
      }
      if (this.minDate) {
        return Date.parse(this.minDate)
      }
      return Date.today()
    },
    isVisible() {
      return this.show && this.showPicker
    },
    isTimePicker() {
      return false
    },
    tmpDateValue() {
      if (this.tmpDate) {
        return Date.parse(this.tmpDate)
      }
      return this.defaultDate
    },
  },
  methods: {
    onInputClick() {
      this.showPicker = true
      this.mode = 'days'
    },
    newDateIsValid(newDate) {
      const minDate = this.minDate || new Date().toString()
      const mD = Date.parse(minDate).clearTime()
      const r = newDate.compareTo(mD)
      return r != -1
    },
    setDate(date_string) {
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      newDate.setMilliseconds(0)
      this.pickedDate = newDate
    },
    onChangeMode(newMode) {
      this.mode = newMode
      if (this.mode == 'hours') {
        this.$emit('reload-availabilities', this.tmpDate)
      }
    },
    onChangeMonth(amount) {
      let newDate = Date.parse(this.tmpDateValue.toString())
      newDate.add(amount).months()
      const mD = Date.parse(this.minDate).clearTime()
      const r = newDate.compareTo(mD)

      if (r == -1) {
        newDate = mD
      }

      if (this.newDateIsValid(newDate)) {
        this.tmpDate = dateToAppFormat(newDate)
      }
    },
    onChangeDay(amount) {
      let newDate = Date.parse(this.tmpDateValue.toString())
      newDate.add(amount).days()
      if (this.newDateIsValid(newDate)) {
        this.tmpDate = dateToAppFormat(newDate)
        this.$emit('reload-availabilities', this.tmpDate)
      }
    },
    onChangeYear(amount) {
      let newDate = Date.parse(this.tmpDateValue.toString())
      newDate.add(amount).year()
      if (this.newDateIsValid(newDate)) {
        this.tmpDate = dateToAppFormat(newDate)
      }
    },
    onHourPicked(hourInfo) {
      this.tmpDate = this.tmpDateValue.clearTime().at({
        hour: hourInfo.hour
      })
      this.showPicker = false
      this.pickedDate = this.tmpDate
      this.$emit('start-picked', this.tmpDateValue.toString(this.formatString), hourInfo.mode)
    },
    onDayPicked(dayNum) {
      let newDate = Date.parse(this.tmpDateValue.toString())
      newDate.set({
        day: dayNum
      })
      if (this.newDateIsValid(newDate)) {
        this.tmpDate = dateToAppFormat(newDate)

        if (this.isTimePicker) {
          this.mode = 'hours'
          this.$emit('reload-availabilities', this.tmpDate)
        } else {
          this.showPicker = false
          this.pickedDate = this.tmpDate
        }
        this.$emit('date-picked', this.tmpDateValue.toString(this.formatString))
      }
    },
    onMonthPicked(month) {
      let newDate = Date.parse(this.tmpDateValue.toString())
      newDate.set({
        month: month
      })
      if (this.newDateIsValid(newDate)) {
        this.tmpDate = dateToAppFormat(newDate)
        this.mode = 'days'
      }
    },
    onYearPicked(year) {
      let newDate = Date.parse(this.tmpDateValue.toString())
      newDate.set({
        year: year
      })
      if (this.newDateIsValid(newDate)) {
        this.tmpDate = dateToAppFormat(newDate)
        this.mode = 'months'
      }
    },
  },
  watch: {
    show(new_value, old_value) {
      if (new_value === true) {
        this.mode = 'days'
      } else {
        this.showPicker = false
      }
    }
  },
  mounted() {
    if (this.forBooking) {
      return
    }
    this.showPicker = true
    this.mode = 'days'
  },
}
</script>
