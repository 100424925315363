<template>
  <div class="start-picker-months">
    <table class="table-condensed">
      <thead>
        <tr>
          <th class="prev" @click.stop="$emit('change-year', -1)">><i class="glyphicon icon-arrow-left"></i></th>
          <th colspan="5" class="switch" @click.stop="$emit('change-mode', 'years')">{{title}}</th>
          <th class="next" @click.stop="$emit('change-year', 1)"><i class="glyphicon icon-arrow-right"></i></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="7">
            <span class="month" v-for="(month, idx) in months" :class="{active: isActive(month)}" @click.stop="$emit('month-picked', idx)">{{month}}</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="7" class="today" style="display: none;">Today</th>
        </tr>
        <tr>
          <th colspan="7" class="clear" style="display: none;">Clear</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    datetime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  },
  computed: {
    dateValue() {
      return Date.parse(this.datetime)
    },
    title() {
      return this.dateValue.toString('MMMM yyyy')
    }
  },
  methods: {
    isActive(month) {
      return this.dateValue.getMonth() == this.months.indexOf(month)
    }
  },
}
</script>
